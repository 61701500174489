<template>
  <div>
    <Main>
      <MaintananceWrapper>
        <img :src="require(`../../static/img/pages/maintenance.svg`)" alt="maintenance" />
        <sdHeading as="h3">Unauthorized Page</sdHeading>
        <p>
          You are not authorized to view this page. Contact support if you believe you should have access to this
          resource.
        </p>
      </MaintananceWrapper>
    </Main>
  </div>
</template>

<script>
import { Main } from '../styled';
import { MaintananceWrapper } from './style';
export default {
  name: 'Maintenance',
  components: {
    Main,
    MaintananceWrapper,
  },
  data() {
    return {};
  },
};
</script>
